import {format, formatDistanceToNow} from 'date-fns';
import {ja} from 'date-fns/locale';

export const formatDate = (timestamp: string): string => {
  return format(new Date(timestamp), 'yyyy/MM/dd');
};

export const formatDateFromIsoDate = (isoDateString: string): string => {
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}/${month}/${day}`;
};

export const formatDateJapanese = (timestamp: string): string => {
  return format(new Date(timestamp), 'yyyy年MM月dd日');
};

export const formatDateDistanceToNow = (timestamp: string): string => {
  return formatDistanceToNow(new Date(timestamp), {locale: ja});
};

export const formatDateToSeconds = (timestamp: string): string => {
  return format(new Date(timestamp), 'yyyy/MM/dd HH:mm:ss');
};

export const formatDateToMinutes = (timestamp: string): string => {
  return format(new Date(timestamp), 'yyyy/MM/dd HH:mm');
};

export const formatDateToMonthDay = (timestamp: string): string => {
  return format(new Date(timestamp), 'MM/dd');
};

export const formatDateToMonthDayZeroSuppress = (timestamp: string): string => {
  return format(new Date(timestamp), 'M/d (eee)', {locale: ja});
};

export const formatDateWithTime = (timestamp: string): string => {
  return format(new Date(timestamp), 'M/d (eee) H:mm', {locale: ja});
};

export const formatDateFullWithTime = (timestamp: string): string => {
  return format(new Date(timestamp), 'yyyy/MM/dd H:mm');
};
