import {z} from 'zod';

export const communityTopicsHeadlineSchema = z.object({
  topics: z.array(
    z.object({
      id: z.number(),
      title: z.string(),
      body: z.string(),
      nickname: z.string(),
      created_at: z.string(), // "2023-03-20 16:35:18 +0900 JST"
      last_posted_at: z.string(), // "2023-03-20 16:35:18 +0900 JST"
    }),
  ),
});

const CommunityTopic = z.object({
  id: z.number(),
  title: z.string(),
  body: z.string(),
  commentCount: z.number(),
  tagNames: z.array(z.string()),
  followerCount: z.number(),
  followed: z.boolean(),
  nickname: z.string(),
  byAdmin: z.boolean(),
  createdAt: z.string(),
  lastPostedAt: z.string(),
});
export type CommunityTopic = z.infer<typeof CommunityTopic>;

export const GetCommunityTopicListResponseSchema = z.object({
  oldestPostedAt: z.string(),
  newestPostedAt: z.string(),
  previousCursor: z.string(),
  nextCursor: z.string(),
  hitCount: z.number(),
  totalCount: z.number(),
  count: z.number(),
  topics: z.array(CommunityTopic),
});
export type GetCommunityTopicListResponse = z.infer<
  typeof GetCommunityTopicListResponseSchema
>;

export const PostCommunityTopicFormInput = z.object({
  nickname: z.string(),
  title: z.string().min(1, 'タイトルを入力してください'),
  body: z.string().min(1, '投稿内容を入力してください'),
  tags: z.array(z.string()),
});
export type PostCommunityTopicFormInput = z.infer<
  typeof PostCommunityTopicFormInput
>;

export const PostCommunityTopicResponseScheme = z.string();
export type PostCommunityTopicResponse = z.infer<
  typeof PostCommunityTopicResponseScheme
>;

export const GetCommunityTopicResponseScheme = CommunityTopic.extend({
  relatedIndustryCategoryId: z.nullable(z.unknown()),
});
export type GetCommunityTopicResponse = z.infer<
  typeof GetCommunityTopicResponseScheme
>;

export const UpdateFollowingCommunityTopicResponseSchema = z.object({
  id: z.number(),
  title: z.string(),
  body: z.string(),
  commentCount: z.number(),
  tagNames: z.array(z.string()),
  followerCount: z.number(),
  nickname: z.string(),
  followed: z.boolean(),
  byAdmin: z.boolean(),
  createdAt: z.string(),
  lastPostedAt: z.string(),
});
export type UpdateFollowingCommunityTopicResponse = z.infer<
  typeof UpdateFollowingCommunityTopicResponseSchema
>;

export const PostAlertCommunityTopicResponseScheme = z.string();
export type PostAlertCommunityTopicResponse = z.infer<
  typeof PostAlertCommunityTopicResponseScheme
>;

export const CommunityDoubtReason = z.object({
  id: z.number(),
  reason: z.string(),
  minDetail: z.number(),
  maxDetail: z.number(),
});
export type CommunityDoubtReason = z.infer<typeof CommunityDoubtReason>;

export const GetCommunityDoubtReasonResponseSchema = z.object({
  doubtReasons: z.array(CommunityDoubtReason),
});
export type GetCommunityDoubtReasonResponse = z.infer<
  typeof GetCommunityDoubtReasonResponseSchema
>;

const CommunityComment = z.object({
  id: z.number(),
  seqNumber: z.number(),
  body: z.string(),
  likeCount: z.number(),
  liked: z.boolean(),
  nickname: z.string(),
  byAdmin: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
});
export type CommunityComment = z.infer<typeof CommunityComment>;

export const GetCommunityCommentsResponseSchema = z.object({
  firstID: z.number(),
  lastID: z.number(),
  previousCursor: z.number(),
  nextCursor: z.number(),
  hitCount: z.number(),
  totalCount: z.number(),
  count: z.number(),
  comments: z.array(CommunityComment),
});
export type GetCommunityCommentsResponse = z.infer<
  typeof GetCommunityCommentsResponseSchema
>;

export const PostCommunityCommentFormInput = z.object({
  nickname: z.string(),
  body: z.string().min(1, '投稿内容を入力してください'),
});
export type PostCommunityCommentFormInput = z.infer<
  typeof PostCommunityCommentFormInput
>;

export const PostCommunityCommentResponseSchema = z.string();
export type PostCommunityCommentResponse = z.infer<
  typeof PostCommunityCommentResponseSchema
>;

export const GetCommunityCommentResponseSchema = CommunityComment;
export type GetCommunityCommentResponse = z.infer<
  typeof GetCommunityCommentResponseSchema
>;

export const UpdateLikedCommunityCommentResponseSchema = CommunityComment;
export type UpdateLikedCommunityCommentResponse = z.infer<
  typeof UpdateLikedCommunityCommentResponseSchema
>;

export const PostAlertCommunityCommentResponseSchema = z.string();
export type PostAlertCommunityCommentResponse = z.infer<
  typeof PostAlertCommunityCommentResponseSchema
>;

export const PostDoubtCommunityCommentFormInput = z.object({
  doubtReasonId: z.string().min(1, '理由を選択してください'),
  detail: z.string(),
});
export type PostDoubtCommunityCommentFormInput = z.infer<
  typeof PostDoubtCommunityCommentFormInput
>;

export const PostDoubtCommunityCommentResponseSchema = z.object({
  byAdmin: z.boolean(),
  createdAt: z.string(),
  doubtClicked: z.boolean(),
  doubtCount: z.number(),
  id: z.number(),
  seqNumber: z.number(),
  updatedAt: z.string(),
});
export type PostDoubtCommunityCommentResponse = z.infer<
  typeof PostDoubtCommunityCommentResponseSchema
>;

const CommunityTag = z.object({
  id: z.number(),
  name: z.string(),
});
export type CommunityTag = z.infer<typeof CommunityTag>;

export const GetCommunityTagListResponseScheme = z.object({
  communityTags: z.array(CommunityTag),
});
export type GetCommunityTagListResponseType = z.infer<
  typeof GetCommunityTagListResponseScheme
>;

export enum CommentListStatusType {
  latest = 'latest',
  oldest = 'oldest',
  other = 'other',
}
export type CommentListStatus =
  | CommentListStatusType.latest
  | CommentListStatusType.oldest
  | CommentListStatusType.other;
