export const filterExistParams = (params: Record<string, any>) => {
  let existParams = {};
  for (const key in params) {
    if (params[key] !== undefined && params[key] !== null) {
      existParams = {...existParams, [key]: params[key]};
    }
  }

  return existParams;
};
